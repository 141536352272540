html {
    scroll-behavior: smooth;
}

*:focus {
    outline: none;
}

.mostly-customized-scrollbar::-webkit-scrollbar {
    width: 1px;
    background-color: #6a6e82;
    height: 5px;
}

.mostly-customized-scrollbar::-webkit-scrollbar-track {
    border: 1px solid #6a6e82;
    background-color: #f5f5f5;
}

.mostly-customized-scrollbar::-webkit-scrollbar-thumb {
    background-color: #6a6e82;
}

body {
    background-color: #f4f5f7;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;

    &.on-joyride {
        overflow: hidden;
        height: 100vh;
    }

    a {
        color: rgb(59, 130, 246);
    }

}

.wrapper-class {
    display: flex;
    flex-direction: column-reverse;
}

.editor-class {
    min-height: 50vh;
    max-height: 50vh;
    background-color: #f9faff;
}

.edit-custom-border {
    border: 1px solid;
    border-color: rgb(31 41 55);
}
